var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: _vm.id } },
    [
      _c("span", { domProps: { innerHTML: _vm._s(_vm.content) } }),
      _vm._v(" "),
      _vm._l(_vm.sections, function(section) {
        return _c("text-section", {
          key: section.id,
          attrs: {
            id: section.id,
            content: section.content,
            sections: section.sections
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }