













import Vue from 'vue';
export default Vue.extend({
  name: 'TextSection',
  props: {
    id: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    sections: {
      type: Array
    }
  }
});
