var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "hover:text-blue-900", attrs: { role: "button" } },
    [
      _c(
        "router-link",
        {
          class: _vm.classes,
          attrs: { tabindex: "0", to: "#" + this.id, append: "" }
        },
        [_vm._v(_vm._s(_vm.name))]
      ),
      _vm._v(" "),
      _vm.depth < _vm.maxDepth
        ? _c(
            "ol",
            _vm._l(_vm.sections, function(section) {
              return _c("text-listing", {
                key: section.id,
                attrs: {
                  id: section.id,
                  name: section.name,
                  depth: section.depth,
                  "max-depth": _vm.maxDepth,
                  sections: section.sections
                }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }