

















import Vue from 'vue';
export default Vue.extend({
  name: 'TextListing',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    depth: {
      type: Number
    },
    maxDepth: {
      type: Number
    },
    sections: {
      type: Array
    }
  },
  computed: {
    classes() {
      return [
        'px-2',
        'py-1',
        'flex',
        'border-2',
        'border-transparent',
        'hover:text-blue-800',
        'focus:text-blue-800',
        'focus:border-2',
        'focus:border-current',
        this.depth == 0 ? 'font-bold' : 'font-base'
      ];
    }
  }
});
