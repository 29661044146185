















































































import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';

import feather from 'feather-icons';
import BackToTop from 'vue-backtotop';
import debounce from 'lodash-es/debounce';

import TextSection from '@/components/text/Section.vue';
import TextListing from '@/components/text/Listing.vue';

function waitForScroll(check: Function, callback: Function) {
  if (check()) {
    callback();
  } else {
    setTimeout(function() {
      waitForScroll(check, callback);
    }, 500);
  }
}

export default Vue.extend({
  name: 'TextView',
  components: {
    BackToTop,
    TextSection,
    TextListing
  },
  data() {
    return {
      searchQuery: '',
      feather
    };
  },
  computed: {
    ...mapState('text', ['candidates']),
    ...mapGetters('text', ['sectionTree'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('clearMessages');
      vm.$store.dispatch('text/findText');
      if (to.hash) {
        waitForScroll(
          function() {
            return document.querySelector(to.hash) != null;
          },
          function() {
            setTimeout(function() {
              const el = document.querySelector(to.hash);
              if (el) {
                el.scrollIntoView();
                // now account for fixed header
                const scrolledY = window.scrollY;

                if (scrolledY) {
                  window.scroll(0, scrolledY - 64);
                }
              }
            }, 500);
          }
        );
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.searchQuery = '';
    if (to.hash) {
      const el = document.querySelector(to.hash);
      if (el) {
        el.scrollIntoView();
        // now account for fixed header
        const scrolledY = window.scrollY;

        if (scrolledY) {
          window.scroll(0, scrolledY - 64);
        }
      }
    }
  },
  methods: {
    ...mapActions('text', ['searchIndex']),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleSearchChange: debounce(function(this: any, text: string) {
      if (text) this.searchIndex(text);
    }, 500),
    handleClick() {
      this.searchQuery = '';
    }
  }
});
