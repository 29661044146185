var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass: "container mx-auto flex flex-col md:flex-row justify-between"
    },
    [
      _c(
        "back-to-top",
        { staticClass: "fixed", attrs: { bottom: "1rem", right: "1rem" } },
        [
          _c(
            "a",
            {
              staticClass:
                "px-3 py-2 border border-black rounded-md shadow bg-black text-white flex items-center focus:outline-none focus:shadow-outline"
            },
            [
              _c("i", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.feather.icons["chevrons-up"].toSvg({ class: "w-4 h-4" })
                  )
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml-2" }, [_vm._v("Back to top")])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("aside", { staticClass: "md:w-1/3 px-2" }, [
        _c(
          "nav",
          {
            staticClass:
              "md:sticky top-0 md:top-16 md:overflow-y-auto md:max-h-(screen-16) text-sm md:text-base"
          },
          [
            _c(
              "ol",
              { staticClass: "p-2 list-none" },
              _vm._l(_vm.sectionTree, function(section) {
                return _c("text-listing", {
                  key: section.id,
                  attrs: {
                    id: section.id,
                    name: section.name,
                    depth: section.depth,
                    "max-depth": 1,
                    sections: section.sections
                  }
                })
              }),
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "my-4 flex flex-col md:w-2/3 px-2" }, [
        _c("header", [
          _c("nav", { staticClass: "relative my-2" }, [
            _c(
              "form",
              {
                staticClass: "flex flex-col",
                attrs: { title: "Search", role: "search", action: "/" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c("div", [
                  _c(
                    "label",
                    { staticClass: "sr-only", attrs: { for: "searchInput" } },
                    [_vm._v("Search")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchQuery,
                        expression: "searchQuery"
                      }
                    ],
                    staticClass:
                      "appearance-none placeholder-gray-600 w-full px-3 py-2 bg-gray-100 border border-gray-500 rounded shadow focus:outline-none focus:shadow-outline",
                    attrs: {
                      id: "searchInput",
                      name: "searchInput",
                      type: "search",
                      role: "searchbox",
                      placeholder: "Search the plan...",
                      required: "required"
                    },
                    domProps: { value: _vm.searchQuery },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.searchQuery = $event.target.value
                        },
                        function($event) {
                          return _vm.handleSearchChange($event.target.value)
                        }
                      ]
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _vm.searchQuery
              ? _c(
                  "div",
                  {
                    staticClass:
                      "absolute top-12 bg-white border rounded shadow-lg"
                  },
                  _vm._l(_vm.candidates, function(candidate, index) {
                    return _c(
                      "div",
                      {
                        key: candidate.id,
                        class: {
                          "w-full": true,
                          "border-b": index + 1 < _vm.candidates.length
                        }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "flex flex-col p-2 hover:bg-blue-100",
                            attrs: { to: "#" + candidate.id, append: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleClick(candidate)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "mx-2 font-semibold" }, [
                              _vm._v(_vm._s(candidate.name))
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "mx-2",
                              domProps: { innerHTML: _vm._s(candidate.content) }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "article",
          { attrs: { id: "tsp-text" } },
          _vm._l(_vm.sectionTree, function(section) {
            return _c("text-section", {
              key: section.id,
              attrs: {
                id: section.id,
                content: section.content,
                sections: section.sections
              }
            })
          }),
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }